import React from "react";
import Helmet from "react-helmet";

import styled from "../theme/styled";

import { ActionLocalLink } from "../components/actionButton";
import MainLayout from "../components/layout/main";
import * as Typo from "../components/typo";

// MARK: Styled

const MainSection = styled.section`
  max-width: 80%;
  height: 100vh;
  margin: -5vh auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Heading = styled(Typo.H1)``;

const Message = styled(Typo.BodyRegular)`
  margin-top: 16px;

  ${({ theme }) => theme.media.tabletMin`
    ${theme.font.size.mediumLarge.cssWithLineHeightRatio(2)}
  `};
`;

const CTAContainer = styled.div`
  margin-top: 56px;
`;

// MARK: Render

export default () => (
  <MainLayout isNavBarFixed={true} isFooterAbsolutelyPositioned={true}>
    {
      // MARK: –– Head
    }
    <Helmet>
      <title>Oroboro | 404</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>

    {
      // MARK: –– Main section
    }
    <MainSection>
      <Heading>Not found</Heading>
      <Message>We can't seem to find the page you're looking for.</Message>
      <CTAContainer>
        <ActionLocalLink
          displaySettings={{
            title: "Return to home page"
          }}
          to="/"
        />
      </CTAContainer>
    </MainSection>
  </MainLayout>
);
